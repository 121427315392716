@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loadingSpinner {
    width: 200px;
    height: 200px;
    border: 10px solid #a0a8ab; /* Light grey */
    border-top: 10px solid #337c75; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin-top: 30%;
  }
  
  .spinnerContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }