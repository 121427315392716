.button{
    border-radius:24px;
    padding: 8px;
    background-color: #337c75;
    color: white;
    font-size:18px;
    text-align: center;
    border: none;
}

.flat{
    border-radius:24px;
    padding: 8px;
    background-color:transparent;
    color: #337c75;
    font-size:18px;
    text-align: center;
}

.clicked{
    opacity: 0.75;
    border-radius: 4px;
}

.button:hover{
    border-radius:24px;
    padding: 8px;
    background-color: #5ccac1;
    color: white;
    font-size:18px;
    text-align: center;
    border: none;
}