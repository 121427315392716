.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonContainer {
  display: flex;
  width: 100%;
  height: 30vh;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
