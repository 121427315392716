.container {
  width: 100%;
  height: 50%;
  padding: 16px 10% ;
  display: flex;
  flex-direction: row;
}

.shiftContainer {
  width: 100%;
}

.shiftTxt {
  font-size: 36px;
  font-weight: bold;
  color: #d9fcfd;
  padding: 8px;
  text-align: center;
}
