
.innerContainer {
  margin: 8px 16px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border:0.5px solid grey;

}

.titleContainerOn {
  width: 100%;
  background-color: #3ab54a;
  position: relative;
  padding: 0px 10%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.titleContainerOff {
  width: 100%;
  background-color: #a0a8ab;
  position: relative;
  padding: 0px 10%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.title {
  color: white;
  font-size: 26px;
}

.textContainerOn {
  width: 100%;
  background-color: #e0f4f3;
  padding: 16px 10%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: flex-start;
  overflow: hidden;
}

.textContainerOff {
  width: 100%;
  background-color: white;
  padding: 16px 10%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: flex-start;
  overflow: hidden;
}

.text {
  margin: 0px 16px;
  font-size: 18px;
}
.textBoldOn {
  font-weight: bold;
  color: #3ab54a;
}

.textBoldOff {
  font-weight: bold;
  color: #a0a8ab;
}
