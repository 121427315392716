.footer {
    width: 100%;
    height: 5rem;
    background-color: transparent;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
  }
.logo {
    display: flex;
    font-family: 'Lato', sans-serif;
    font-size: 10rem;
    color: white;
    margin: 0;
    justify-content: space-evenly;
  }

.footerImg{
    width: 30rem;
    height: 10rem;
  }