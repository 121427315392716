.container{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }
  .titleTxt {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
  }
  .valueTxt {
    font-size: 18px;
    font-weight: bold;
    color: #337c75;
    margin-top: 8px;
    margin-bottom: 8px;
  }