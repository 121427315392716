.container {
  width: 100%;
  height: 100%;
  display: flex;
}
.innerContainer {
  width: 100%;
  height: 100%;
  padding: 5rem;
  padding-top: 0;
  /* background-image:url('../../assets/PANApp-bg-HD.jpg'); */
  display: grid;
  text-align: justify;
}

.image {
  width: 100%;
  height: 80%;
  padding: 2rem;
}
.main-image {
  width: 60%;
  height: 20rem;
  z-index: 0;
  overflow: hidden;
  margin-left: 24px;
  margin-right: 24px;
}

.text {
  /* padding: 5rem; */
  width: 50%;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
}

.buttonContainer {
  width: 50%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
}

.buttons {
  padding: 5rem;
  margin: 12px;
  width: "100%";
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  width: 50%;
}
.infoText {
  font-size: 14px;
}
