.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  }

.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  /* background-color: #38015c; */
  padding: 1rem;
  color: white;
}

.auth h1 {
  text-align: center;
  color: #337c75;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #a0a8ab;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
}

.control input  {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #a0a8ab;
  width: 100%;
  text-align: left;
  padding: 1rem;
}

.control select  {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #a0a8ab;
  width: 100%;
  text-align: left;
  padding: 1rem;
}

.control option:hover{
  color: white !important;
  background-color: #337c75 !important;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  width: 100%;
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #337c75;
  border: 1px solid #337c75;
  border-radius: 8px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #337c75;
  border-color: #337c75;
}

.actions .toggle {
  width: fit-content;
  margin-top: 1rem;
  background-color: transparent;
  color: #337c75;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #5ccac1;
}

.flat_text{
  width: 100%;
  font:inherit;
  color: #a0a8ab;
}