.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-self: center;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  align-items: center;
}
.buttons {
  margin-top: 10%;
  /* margin-bottom: 10%; */
}
.inputContainer {
  margin-left: 8px;
  margin-right: 8px;
}

.control {
  margin-bottom: 0.5rem;
  width: 50%;
}

.control label {
  display: block;
  color: #a0a8ab;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #a0a8ab;
  width: 100%;
  text-align: left;
  padding: 1rem;
}
.control select  {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #a0a8ab;
  width: 100%;
  text-align: left;
  padding: 1rem;
}

.control option:hover{
  color: white !important;
  background-color: #337c75 !important;
}