.buttonContainer {
  width: 100%;
}

.customButton {
  width: 100%;
  height: fit-content;
  font-size: 24px;
  background-color: transparent;
  border: none;
  text-align: left;
  color: #d9fcfd;
  padding: 8px;
}

.customButton:hover {
  background-color: #5ccac1;
}
