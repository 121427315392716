.wrapper {
    border: none;
    padding-bottom: 15vh;
    padding-left: 2vw;
    padding-right: 2vw;
    /* padding:5% 16px; */
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
  .barContainer {
    width: 100%;
    height: 40px;
    background-color: #a0a8ab;
  }
  
  .barCompleted {
    width: 100%;
    background-color: #337c75;
    width: 30%;
    height: 40px;
  }