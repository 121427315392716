.text {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.authContent {
    margin-left: 40%;
    margin-right: 40%;
    padding: 16px;
    border-radius: 8px;
    background-color: #337c75;
    height: fit-content;
    align-self: center;
}
.buttons {
  margin: 8px;
}
.innerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: #a0a8ab;
  /* font-weight: bold; */
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 8px;
  border: 1px solid #a0a8ab;
  width: 100%;
  text-align: left;
  padding: 1rem;
}
