.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3vh;
}
.question {
  font-size: 24px;
  font-weight: bold;
  color: #337c75;
}

.innerContainer {
  display: flex;
  flex-direction: row;
}

.answer {
  margin: 16px;
  display: flex;
  flex-direction: column;
}

input[type="radio"] {
  transform: scale(2); /* Increase the scale to make the radio button larger */
  margin-right: 8px; /* Adjust the spacing between the radio button and the label */
}
