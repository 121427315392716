.side_bar {
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #337c75;
}

.infoContainer {
  width: 100%;
}

.infoHeader {
  font-size: 24px;
  font-weight: bold;
  color: #d9fcfd;
  margin: 0px 8px;
}

.infoTxt {
  font-size: 20px;
  font-weight: normal;
  color: #5ccac1;
  margin: 0px 8px;
}
.shiftContainer {
  width: 100%;
  background-color: #3ab54a;
}

.shiftTxt {
  font-size: 36px;
  font-weight: bold;
  color: #d9fcfd;
  padding: 8px;
}

.buttonContainer {
  width: 100%;
}

.customButton {
  width: 100%;
  height: fit-content;
  font-size: 24px;
  background-color: transparent;
  border: none;
  text-align: left;
  color:#d9fcfd;
  padding: 8px;
}

.customButton:hover{
  background-color:#5ccac1;

}

/* for dropdown menu on drivers */
.dropdownmenu {
  z-index: 2;
  flex-direction: column;
  border-radius: 8px;
  overflow:hidden; 
  overflow-y:scroll;
  height:20vh;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdownoption {
  padding: 12px 12px;
  color: white;
  width: 100%;
  text-align: center;
  font-size:16px
}

.dropdownoption:hover {
  padding: 12px 12px;
  border-radius: 8px;
  background-color: #337c75;
  color: white;
  width: 100%;
  cursor: pointer;
  text-align: center;

}