.container{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:24px;
}
.container2{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width: 100%;
}
.tabTitle{
    padding:8px 16px;
    font-size:26px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}
.contentContainer{
    background-color: white;
    padding:16px;
    width:100%;
    border-radius: 8px;
    height: 80vh;
    overflow:hidden; 
    overflow-y:scroll;
}

.contentContainer p{
    font-size:24px;
}

.paragraph{
    text-align:center;
    font-size: 32px;
    font-weight:bold;
}
.item_list{
    font-size: 32px;
    font-weight:bold;
}