.container {
  width: 100%;
  height: 30%;
  /* padding: 16px 10%;
  
  flex-direction: column; */
  display: flex;
  justify-content: center;
}

.innerContainer{
  width: 100%;
  height: 100%;
  padding: 16px 10%;
  display: flex;
  flex-direction: column;
}

.shiftContainer {
  width: 200px;
}

.shiftTxt {
  font-size: 36px;
  font-weight: bold;
  color: #d9fcfd;
  padding: 8px;
  text-align: center;
}
.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  align-self: center;
}
.headerTxt {
  height: 10%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #337c75;
  padding: 8px;
}
table,
th,
td {
    text-align: center;
  border: 3px solid #337c75;
  border-collapse: collapse;
  width: 100%;
  margin: 4px;
}
table {
  height: max-content;
}

tr {
  width: 100%;  
  display: flex;
}
.emptyShell{
    border-color:transparent;

}
.buttonContainer{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top:2vh;
  margin-left: 4vw;
  margin-right: 4vw;

}
