.outerView {
  width: "100%";
  height: "100%";
  overflow-y: "scroll";
}

.customRateBar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 8px;
}
.starImg {
  width: 40px;
  height: 40px;
  resize: both;
  margin-top: 16px;
  margin-bottom: 16px;
}

.qualityRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.customImg {
  width: 60px;
  height: 60px;
  margin-left: 8px;
  margin-right: 8px;
}
.questionView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-bottom-width: 1px;
  border-bottom-color: #a0a8ab;
  /* margin: 10%; */
}
.titleText {
  font-size: 22px;
  font-weight: bold;
  align-self: center;
  text-align: center;
}
.shareTxt {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  /* text-align: "top"; */
  padding: 6px;
  border-radius: 6px;
  border-color: #a0a8ab;
  border-width: 1px;
  /* margin-left: 8px;
  margin-right: 8px; */
  font-size: 22px;
  width: 50%;
align-self: center;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
  }

.buttons {
    margin: 12px;
    border-radius: 24px;
    padding: 8px;
    background-color: #337c75;
    width: 20%;
    border: none;
    color: white;
    font-size:22px;
  }
