@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  /* background-color: #ffffff; */
  background-image:url('./assets/PANApp-bg-HD.jpg');
}

#root{
  height: 100%;
}