.headerTxt {
  height: 10%;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #337c75;
  padding: 8px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questionView {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  border-bottom-width: 1px;
  border-bottom-color: #a0a8ab;
  margin: 8px;
  width: 100%;
}
.titleText {
  font-size: 22px;
  font-weight: bold;
  align-self: center;
  text-align: center;
  color: #337c75;
}
.shareTxt {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  padding: 6px;
  border-radius: 6px;
  border-color: #a0a8ab;
  border-width: 1px;
  font-size: 22px;
  width: 50%;
  align-self: center;
}
