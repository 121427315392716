.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: 5%; */
    margin-left: 10%;
    margin-right: 10%;
    align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
.innerContainer{
  width: 100%;
  height: 100%;
  padding: 16px 10%;
  display: flex;
  flex-direction: column;
}
.text {
  font-size: 24px;
  font-weight: bold;
  color: #337c75;
  margin-left: 24px;
  margin-right: 24px;
  text-align: center;
}
.row {
  flex-direction: row;
  height: 200px;
  margin-left: 8px;
  margin-right: 8px;
}
.buttonContainer {
    display: flex;
  width:100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-width: 1px;
  border-top-color: #337c75;
}
.button {
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 4px 8px;
}
.arrowContainer{
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
}
.img{
  width:5vw;
  height:2vw;
}